import { SimpleResponse, UserData, UserDataFull } from '../libs/types';
import { instance, instanceAnonymous } from './axios';

export const userAPI = {
  me(): Promise<UserDataFull> {
    return instance.get(`users/me`).then((response) => response.data);
  },
  get(id: string): Promise<UserData> {
    return instance.get(`users/${id}`).then((response) => response.data);
  },
  getByPublicUrl(publicID: string): Promise<UserData> {
    return instance.get(`users/public/${publicID}`).then((response) => response.data);
  },
  updateUserInfo(
    id: string,
    name: string,
    avatar_id: number,
    country_code: string | null,
    language_code: string,
  ): Promise<SimpleResponse> {
    return instance
      .patch(`users/${id}`, JSON.stringify({ name, avatar_id, country_code, language_code }))
      .then((response) => response.data);
  },
  updateUser(
    id: string,
    userInfo: {
      name?: string;
      avatar_id?: number;
      country_code?: string | null;
      language_code?: string;
    },
  ): Promise<SimpleResponse> {
    return instance
      .patch(`users/${id}`, JSON.stringify({ ...userInfo }))
      .then((response) => response.data);
  },
  updatePassword(id: string, oldPassword: string, newPassword: string): Promise<SimpleResponse> {
    return instance
      .post(
        `users/${id}/password`,
        JSON.stringify({
          old_password: oldPassword,
          new_password: newPassword,
        }),
      )
      .then((response) => response.data);
  },
  changeEmail(id: string, email: string, password: string): Promise<SimpleResponse> {
    return instance
      .post(`users/${id}/email/change`, JSON.stringify({ email, password }))
      .then((response) => response.data);
  },
  changeEmailApply(id: string, email: string, code: string): Promise<SimpleResponse> {
    return instance
      .post(`users/${id}/email/change/apply`, JSON.stringify({ email, code }))
      .then((response) => response.data);
  },
  verifyEmail(id: string, email: string): Promise<SimpleResponse> {
    return instance
      .post(
        `users/${id}/email/verify`,
        JSON.stringify({
          email,
        }),
      )
      .then((response) => response.data);
  },
  verifyEmailApply(id: string, email: string, code: string): Promise<SimpleResponse> {
    return instance
      .post(
        `users/${id}/email/verify/apply`,
        JSON.stringify({
          email,
          code,
        }),
      )
      .then((response) => response.data);
  },
  recoveryPassword(email: string): Promise<SimpleResponse> {
    return instanceAnonymous
      .post(`users/password/recovery`, JSON.stringify({ email }))
      .then((response) => response.data);
  },
  verifyPassword(email: string, code: string): Promise<SimpleResponse> {
    console.log(
      JSON.stringify({
        email,
        code,
      }),
    );
    return instanceAnonymous
      .post(
        `users/password/verify`,
        JSON.stringify({
          email,
          code,
        }),
      )
      .then((response) => response.data);
  },
  resetPassword(email: string, code: string, password: string): Promise<SimpleResponse> {
    return instanceAnonymous
      .post(
        `users/password/reset`,
        JSON.stringify({
          email,
          code,
          password,
        }),
      )
      .then((response) => response.data);
  },
  stats(): Promise<any> {
    return instance.get(`users/stats`).then((response) => response.data);
  },
};
