import { FC, ReactElement } from 'react';
import { AuthContextProvider } from '@contexts/AuthContext';
import { SettingsProvider } from '@contexts/SettingsContext';
import { UserBoardProvider } from '@contexts/useUserBoard';
import ModalManagerProvider from '@pages/Game/contexts/ModalManagerContext';
import AudioContext from '@contexts/AudioContext';
import { FeatureFlagsProvider } from '@contexts/FeatureFlagsContext';

interface ContextProviderProps {
  children: ReactElement | ReactElement[];
}

const contextProviders = [
  AuthContextProvider,
  SettingsProvider,
  UserBoardProvider,
  ModalManagerProvider,
  AudioContext,
  FeatureFlagsProvider,
];

const ContextProvider: FC<ContextProviderProps> = ({ children }) => {
  return contextProviders.reduceRight((memo, Context) => {
    return <Context>{memo}</Context>;
  }, children);
};

export default ContextProvider;
