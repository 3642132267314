import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  lazy,
  Suspense,
  useEffect,
} from 'react';

const PlayWithBotModal = lazy(() => import('@components/PlayVsComputerModal'));
const SelectMiningModeTimeFrameModal = lazy(
  () => import('@pages/Game/components/modals/SelectMiningModeTimeFrameModal'),
);

interface ModalContextProps {
  openModal: (name: ModalName, data?: any) => void;
  closeModal: (name: ModalName) => void;
}

export enum ModalName {
  MINING_TIME_FRAME = 'MINING_TIME_FRAME',
  PLAY_WITH_BOT = 'PLAY_WITH_BOT',
}

interface ModalState {
  isOpen: boolean;
  data?: any;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

interface ModalManagerProps {
  children: ReactNode;
}

const ModalManagerProvider: React.FC<ModalManagerProps> = ({ children }) => {
  const [modals, setModals] = useState<{ [key in ModalName]: ModalState }>({
    PLAY_WITH_BOT: { isOpen: false },
    MINING_TIME_FRAME: { isOpen: false },
  });

  const openModal = (name: ModalName, data?: any) => {
    setModals((prev) => ({ ...prev, [name]: { isOpen: true, data } }));
  };

  const closeModal = (name: ModalName) => {
    setModals((prev) => ({ ...prev, [name]: { ...prev[name], isOpen: false } }));
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}

      <Suspense fallback={null}>
        <PlayWithBotModal
          isOpen={modals.PLAY_WITH_BOT.isOpen}
          onClose={() => closeModal(ModalName.PLAY_WITH_BOT)}
        />
        <SelectMiningModeTimeFrameModal
          isOpen={modals.MINING_TIME_FRAME.isOpen}
          onClose={() => closeModal(ModalName.MINING_TIME_FRAME)}
        />
      </Suspense>
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextProps => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

export default ModalManagerProvider;
