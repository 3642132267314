import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAuth } from '@contexts/AuthContext';
import Loader from './Loader';
import TagManager from 'react-gtm-module';

const RequireAuth = () => {
  const location = useLocation();
  const { currentUser, isRefreshing } = useAuth();

  if (currentUser) {
    TagManager.dataLayer({
      dataLayer: {
        userId: currentUser.id,
      },
      dataLayerName: 'UserDataLayer',
    });

    if (!currentUser.email_verified) {
      return <Navigate to={'/registration/verify'} state={{ from: location }} replace />;
    }

    return isRefreshing ? <Loader /> : <Outlet />;
  }

  return localStorage.getItem('isWelcomeScreenSeen') ? (
    <Navigate to={'/login'} state={{ from: location }} replace />
  ) : (
    <Navigate to={'/presentation'} state={{ from: location }} replace />
  );
};

export default observer(RequireAuth);
